import React from "react";
import styles from "./style.scss";
export const Loading = () => {
    return (React.createElement("div", { className: styles.loading },
        React.createElement("div", null),
        React.createElement("div", null),
        React.createElement("div", null)));
};
export function Preloader({ fields, children, }) {
    const loaded = fields.every((f) => f !== undefined);
    if (loaded)
        return React.createElement(React.Fragment, null, children);
    return (React.createElement("div", { className: styles.container },
        React.createElement(Loading, null)));
}
