import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { Menu, User } from "lucide-react";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";
import { Button } from "../ui/fields/button";
import { _NavOutlet } from "./nav";
import styles from "./shell.scss";
import { useLocalStorageState } from "../support/hooks";
import { Input } from "../ui/fields/input";
import { useInjection } from "inversify-react";
import { ShellService } from "../services/shell";
const times = [
    [17, "evening"],
    [12, "afternoon"],
    [0, "morning"],
];
const lightModeLabels = [
    "Light Mode",
    "Beige Mode",
    "Toast Mode",
    "White Mode",
    "Bright Mode",
    "WHYte Mode",
    "SPF 0 Mode",
];
let lastLightModeIdx = 5;
const LightModeToggle = ({ lightMode, setLightMode, }) => {
    const lightModeIdx = useMemo(() => {
        let newL;
        while (newL === undefined || newL === lastLightModeIdx)
            newL = Math.floor(Math.random() * lightModeLabels.length);
        return (lastLightModeIdx = newL);
    }, []);
    return (React.createElement(Input, { type: "checkbox", label: lightModeLabels[lightModeIdx], checked: lightMode, onChange: (e) => {
            setLightMode(e.target.checked);
        } }));
};
export function Header({ pages, active, }) {
    const [open, setOpen] = useState(false);
    const { user, logout } = useAuth0();
    const getToD = () => {
        const h = new Date().getHours();
        return times.find(([t]) => Number(t) <= h)?.[1];
    };
    const [lightMode, setLightMode] = useLocalStorageState("shell--light-mode", false);
    useEffect(() => {
        if (lightMode) {
            document.body.classList.remove("dark");
            document.body.classList.add("light");
        }
        else {
            document.body.classList.add("dark");
            document.body.classList.remove("light");
        }
    }, [lightMode]);
    const [notifications, setNotifications] = useLocalStorageState("shell--notifications", Notification.permission === "granted");
    useEffect(() => {
        Notification.permission !== "granted" && setNotifications(false);
    }, []);
    const requestPermission = async () => {
        setNotifications(true);
        const state = await Notification.requestPermission();
        if (state !== "granted")
            setNotifications(false);
    };
    const shell = useInjection(ShellService);
    useEffect(() => {
        if (!notifications)
            return;
        const sub = shell.notifications$.subscribe((n) => {
            new Notification(n.source, {
                body: n.message,
                icon: "https://brawlhalla.brawltools.com/favicon.png",
            });
        });
        return () => sub.unsubscribe();
    }, [notifications, shell]);
    return (React.createElement("div", { className: styles.header },
        React.createElement(Popup, { open: open, trigger: React.createElement("div", { className: styles.title },
                React.createElement(Menu, null),
                active?.title && React.createElement("span", null, active?.title)), position: "bottom left", onOpen: () => setOpen(true), onClose: () => setOpen(false), arrow: false },
            React.createElement("div", { className: "popup-menu" }, pages.map((page) => (React.createElement(NavLink, { key: page.slug, onClick: () => setOpen(false), className: ({ isActive }) => (isActive ? "active" : ""), to: page.slug }, page.title))))),
        React.createElement(_NavOutlet, { className: styles.nav }),
        React.createElement(Popup, { on: "hover", trigger: React.createElement("div", { className: styles.profile }, user?.picture ? (React.createElement("img", { src: user?.picture, alt: user.name })) : (React.createElement(User, null))), position: "bottom right", arrow: false },
            React.createElement("div", { className: styles.profilePopup },
                React.createElement("span", null,
                    "Good ",
                    getToD(),
                    ","),
                React.createElement("div", null, user?.name),
                React.createElement("div", { style: {
                        margin: "6px 0 10px",
                        display: "flex",
                    } },
                    React.createElement(LightModeToggle, { ...{ lightMode, setLightMode } })),
                React.createElement("div", { style: {
                        margin: "6px 0 10px",
                        display: "flex",
                    } },
                    React.createElement(Input, { type: "checkbox", label: "Push Notifications", checked: notifications, onChange: (e) => e.target.checked ? requestPermission() : setNotifications(false) })),
                React.createElement(Button, { onClick: () => logout({ returnTo: window.location.origin }) }, "Logout")))));
}
