import { lazy } from "react";
export const pages = [
    {
        title: "Tournament",
        slug: "/tournament",
        scope: "pages:tournament",
        component: "./tournament",
    },
    {
        title: "Crew Battle",
        slug: "/crew-battle",
        scope: "pages:crew-battle",
        component: "./crew-battle",
    },
    {
        title: "Odin's Journal",
        slug: "/odins-journal",
        scope: "pages:odins-journal",
        component: "./odins-journal",
    },
    {
        title: "Admin",
        slug: "/admin",
        scope: "pages:admin",
        component: "./admin",
    },
].map((p) => ({
    ...p,
    component: lazy(() => import(
    /* webpackInclude: /\.tsx$/ */
    /* webpackExclude: /cards/ */
    /* webpackChunkName: "pages/[request]" */
    `${p.component}`)),
}));
