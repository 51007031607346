var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { injectable } from "inversify";
import ioreq from "socket.io-request";
import { io } from "socket.io-client";
import { BehaviorSubject } from "rxjs";
let SocketService = class SocketService {
    socket;
    ioreq;
    _ready;
    _readyRes;
    connected$ = new BehaviorSubject(false);
    latency$ = new BehaviorSubject(0);
    initialized = false;
    constructor() {
        this._ready = new Promise((res) => (this._readyRes = res));
    }
    initialize(token) {
        this.socket = io({
            reconnectionDelay: 300,
            reconnectionDelayMax: 300,
            path: `/api/backend`,
            auth: {
                token,
            },
            transports: ["websocket"],
            upgrade: false,
        });
        this.socket.on("connect", () => {
            if (this.initialized) {
                // Reconnection, we need to refresh to ensure we have the latest
                // things from the backend.
                window.location.reload();
            }
            console.log("Connected to server!");
            this.ioreq = ioreq(this.socket);
            this.socket.once("ready", () => {
                console.log("Ready!");
                this.initialized = true;
                this._readyRes();
                this.connected$.next(true);
                this._latencyTest();
            });
        });
        this.socket.on("disconnect", () => {
            console.error("Disconnected from backend!");
            this.connected$.next(false);
        });
    }
    async _latencyTest() {
        const t = performance.now();
        await this.request("/ping");
        this.latency$.next((performance.now() - t) / 2);
        setTimeout(() => this._latencyTest(), 2000);
    }
    async request(endpoint, ...args) {
        await this._ready;
        return await this.ioreq.request(endpoint, args);
    }
    async once(ev, listener) {
        await this._ready;
        this.socket.once(ev, listener);
    }
    async on(ev, listener) {
        await this._ready;
        this.socket.on(ev, listener);
    }
    async off(ev, listener) {
        await this._ready;
        this.socket.off(ev, listener);
    }
    async emit(ev, ...args) {
        await this._ready;
        this.socket.emit(ev, ...args);
    }
};
SocketService = __decorate([
    injectable(),
    __metadata("design:paramtypes", [])
], SocketService);
export { SocketService };
