import React, { useContext } from "react";
import { useUpdate } from "../../support/hooks";
const DirtyContext = React.createContext({});
export const useDirty = () => useContext(DirtyContext);
export function useDirtyInput(v) {
    const { onDirty } = useDirty();
    useUpdate(() => onDirty?.(), [v]);
    return false;
}
export function useDirtyContainer([dirty, setDirty]) {
    return {
        dirty: Boolean(dirty),
        markReset: () => setDirty(false),
        markDirty: () => setDirty(true),
        wrapDirty: (children) => (React.createElement(DirtyContext.Provider, { value: {
                onDirty: () => setDirty(true),
            } }, children)),
    };
}
export const DirtyTrigger = ({ v }) => {
    useDirtyInput(v);
    return null;
};
