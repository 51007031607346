import { TSystem } from "@bh-stream-toolkit/tokens";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import React from "react";
import { useEffect, useState } from "react";
import { ShellService } from "../services/shell";
import { SocketService } from "../services/socket";
import { useBackend } from "../support/backend";
import { useUpdate } from "../support/hooks";
import styles from "./shell.scss";
import { Info, Skull, XOctagon } from "lucide-react";
import { Button } from "../ui/fields/button";
export const NotificationContainer = () => {
    // Notification rendering logic
    const [notifications, setNotifications] = useState([]);
    const shell = useInjection(ShellService);
    const socket = useInjection(SocketService);
    useEffect(() => {
        const sub = shell.notifications$.subscribe((notif) => setNotifications((n) => [notif, ...n]));
        return () => sub.unsubscribe();
    }, [shell]);
    const b = useBackend(TSystem);
    const status = b.useState("status", {
        fatal: false,
        statsA: {
            stats: false,
            game: false,
        },
        statsB: {
            stats: false,
            game: false,
            available: false,
        },
        active: "A",
    })[0];
    const useStats = b.useState("useStats")[0];
    const { statsA, statsB } = status;
    const [connected, setConnected] = useState(socket.socket?.connected || false);
    useEffect(() => {
        const sub = socket.connected$.subscribe((c) => setConnected(c));
        return () => sub.unsubscribe();
    }, [socket, setConnected]);
    useUpdate(() => {
        if (!connected)
            shell.notify("Reconnecting...", "System", "critical");
    }, [connected]);
    useUpdate(() => {
        if (useStats && connected && !statsA.stats)
            shell.notify("Lost connection!", "Stats Analyzer A", "critical");
    }, [useStats, connected, statsA.stats]);
    useUpdate(() => {
        if (useStats && connected && statsA.stats && !statsA.game)
            shell.notify("Brawlhalla is not running!", "Statsbot A", "critical");
    }, [useStats, connected, statsA.stats, statsA.game]);
    useUpdate(() => {
        if (useStats && connected && statsB.available && !statsB.stats)
            shell.notify("Lost connection!", "Stats Analyzer B", "critical");
    }, [useStats, connected, statsB.stats]);
    useUpdate(() => {
        if (useStats &&
            connected &&
            statsB.stats &&
            statsB.available &&
            !statsB.game)
            shell.notify("Brawlhalla is not running!", "Statsbot B", "critical");
    }, [useStats, connected, statsB.stats, statsB.game]);
    const counts = {
        info: 0,
        error: 0,
        critical: 0,
    };
    notifications.map((n) => counts[n.level]++);
    return (React.createElement("div", { className: classNames(styles.notifications, {
            [styles.visible]: notifications.length,
        }) },
        React.createElement("div", { className: styles.notificationsContainer }, notifications.map((n) => (React.createElement("div", { onClick: () => setNotifications(notifications.filter((not) => not.id !== n.id)), key: n.id, className: classNames(styles.notification, {
                [styles.error]: n.level === "error",
                [styles.critical]: n.level === "critical",
                [styles.info]: n.level === "info",
            }) },
            React.createElement("div", { className: styles.body },
                n.source && React.createElement("div", { className: styles.source }, n.source),
                React.createElement("div", null, n.message)))))),
        React.createElement("div", { className: styles.notificationHeader },
            React.createElement("ul", { className: styles.counts },
                React.createElement("li", null,
                    React.createElement(Info, null),
                    React.createElement("span", null, counts.info)),
                React.createElement("li", null,
                    React.createElement(XOctagon, null),
                    React.createElement("span", null, counts.error)),
                React.createElement("li", null,
                    React.createElement(Skull, null),
                    React.createElement("span", null, counts.critical))),
            React.createElement(Button, { onClick: () => setNotifications([]) }, "Clear"))));
};
