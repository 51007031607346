import classNames from "classnames";
import { CheckCircle, Circle } from "lucide-react";
import React, { useEffect, useState, } from "react";
import { useDirtyInput } from "./dirtyable";
import styles from "./fields.scss";
export const Input = React.forwardRef(({ dirtyable = true, label, type, className, inverted, big, value, onChange, checked, ...props }, ref) => {
    const [_value, _setValue] = useState(value);
    const [_checked, setChecked] = useState(checked);
    const dirty = useDirtyInput(dirtyable ? _value : "");
    useEffect(() => _setValue(value), [value]);
    useEffect(() => setChecked(checked), [checked]);
    const tag = type === "textarea" ? "textarea" : "input";
    const field = React.createElement(tag, {
        ref,
        type,
        className: classNames(className, styles.input, {
            [styles.dirty]: dirty || inverted,
            [styles.big]: big,
        }),
        value: _value,
        checked: _checked,
        onChange(e) {
            _setValue(e.target.value);
            setChecked(e.target.checked);
            onChange && onChange(e);
        },
        ...props,
    });
    if (label) {
        return (React.createElement("label", { className: classNames(styles.label, {
                [styles.inline]: ["checkbox", "radio"].includes(type),
            }) },
            React.createElement("span", null, label),
            field,
            type === "checkbox" && (_checked ? React.createElement(CheckCircle, null) : React.createElement(Circle, null))));
    }
    return field;
});
Input.displayName = "Input";
