var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from "inversify";
import { Subject } from "rxjs";
import { SocketService } from "./socket";
let ShellService = class ShellService {
    notifications$ = new Subject();
    id = 0;
    constructor(socket) {
        // Display backend errors as notifications.
        socket.on("/shell/error", (message, source, fatal) => {
            console.error(`${fatal ? "Fatal " : ""}Backend Error${source ? " [" + source + "]" : ""}:`, message);
            this.notify(message, source, fatal ? "critical" : "error");
            if (fatal)
                this.notify("A fatal error occured, some things may not work. Please call a technical break and contact preyneyv or partofthecreed immediately.", "System", "critical");
        });
    }
    notify(message, source, level = "info") {
        this.notifications$.next({ message, source, level, id: this.id++ });
    }
};
ShellService = __decorate([
    injectable(),
    __param(0, inject(SocketService)),
    __metadata("design:paramtypes", [SocketService])
], ShellService);
export { ShellService };
