import React, { Suspense, useState, useEffect } from "react";
import { Provider as InversifyProvider, useInjection } from "inversify-react";
import { ConduitProvider } from "react-conduit";
import { Route, Navigate, BrowserRouter as Router, Routes, } from "react-router-dom";
import { container } from "../support/di-container";
import { pages } from "../pages";
import { Header } from "./header";
import styles from "./shell.scss";
import { useTitle } from "../support/hooks";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { HOST } from "../support/config";
import jwtDecode from "jwt-decode";
import { AuthPage } from "./auth";
import { SocketService } from "../services/socket";
import { StatusService } from "../services/status";
import { NotificationContainer } from "./notifications";
function PageWrapper({ page, onActive }) {
    useEffect(() => {
        onActive();
    }, [onActive]);
    useTitle(page.title);
    return React.createElement(page.component, null);
}
function pageRoute({ page, setActive, }) {
    return (React.createElement(Route, { key: page.slug, path: page.slug, element: React.createElement(PageWrapper, { page: page, onActive: () => setActive(page) }) }));
}
export function _App() {
    // Initialize the status service
    useInjection(StatusService);
    const [active, setActive] = useState(null);
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [scopes, setScopes] = useState([]);
    const socket = useInjection(SocketService);
    useEffect(() => {
        if (isLoading || !isAuthenticated)
            return void setScopes([]);
        getAccessTokenSilently().then((token) => {
            const decoded = jwtDecode(token);
            setScopes(decoded.scope.split(" "));
            socket.initialize(token);
        });
    }, [isAuthenticated, isLoading]);
    const scopedPages = pages.filter((p) => !p.scope || scopes.includes(p.scope));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.app },
            isAuthenticated && React.createElement(Header, { pages: scopedPages, active: active }),
            React.createElement("div", null,
                React.createElement(Suspense, { fallback: React.createElement("div", null) },
                    React.createElement(Routes, null,
                        scopedPages.length && (React.createElement(React.Fragment, null,
                            React.createElement(Route, { path: "/", element: React.createElement(Navigate, { replace: true, to: scopedPages[0].slug }) }),
                            scopedPages.map((page) => pageRoute({ page, setActive })))),
                        React.createElement(Route, { path: "*", element: React.createElement(AuthPage, null) }))))),
        React.createElement(NotificationContainer, null)));
}
export function App() {
    return (React.createElement(Auth0Provider, { domain: "dev-ctkqwk7x.us.auth0.com", clientId: "qRd5Sy7VLpiKy8CEm4tUdoK7KkflacAE", redirectUri: window.location.origin, scope: pages
            .map((p) => p.scope)
            .filter((s) => s)
            .join(" "), audience: HOST },
        React.createElement(ConduitProvider, null,
            React.createElement(InversifyProvider, { container: container },
                React.createElement(Router, null,
                    React.createElement(_App, null))))));
}
