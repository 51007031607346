import { useEffect, useState, useRef, useMemo, } from "react";
/**
 * A variation of use-async-memo from NPM that actually handles undefined and
 * null predictably.
 */
export function useAsyncMemo(factory, deps, initial) {
    const [val, setVal] = useState(initial);
    useEffect(() => {
        let cancel = false;
        setVal(undefined);
        factory().then((newVal) => {
            if (!cancel) {
                setVal(newVal);
            }
        });
        return () => {
            cancel = true;
        };
    }, deps);
    return val;
}
/**
 * Store a copy of the state in local storage and restore it on page load.
 */
export function useLocalStorageState(name, initialValue) {
    const [value, _setValue] = useState(localStorage[name] ? JSON.parse(localStorage[name]) : initialValue);
    const setValue = (v) => {
        _setValue(v);
        localStorage[name] = JSON.stringify(v);
    };
    return [value, setValue];
}
/**
 * Call a callback synchronously only once.
 */
export function useBeforeMount(cb) {
    const didMount = useRef(false);
    if (didMount.current)
        return;
    cb();
    didMount.current = true;
}
/**
 * Bind a keyboard shortcut across the application. Setting global to true
 * triggers the callback even if a field is active. False means there should be
 * no active field.
 */
export function useKeybind(cb, key, global, modifiers = {}) {
    const alt = !!modifiers.alt, ctrl = !!modifiers.ctrl, shift = !!modifiers.shift;
    useEffect(() => {
        function onKeyDown(e) {
            if (!global && document.activeElement)
                return; // Another element has focus.
            if (e.code !== key)
                return; // incorrect key
            if (alt !== e.altKey || ctrl !== e.ctrlKey || shift !== e.shiftKey)
                return; // missing modifiers
            e.preventDefault();
            cb();
        }
        document.body.addEventListener("keydown", onKeyDown);
        return () => document.body.removeEventListener("keydown", onKeyDown);
    }, [cb, key, global, modifiers]);
}
export function useTitle(title) {
    useEffect(() => {
        const oldTitle = document.title;
        document.title = `${title} · Stream Toolkit`;
        return () => void (document.title = oldTitle);
    }, [title]);
}
export function debounce(ms) {
    let interval;
    let onCancel = () => {
        /**/
    };
    return (cb, cancel) => {
        onCancel();
        window.clearTimeout(interval);
        onCancel =
            cancel ||
                (() => {
                    /**/
                });
        interval = window.setTimeout(cb, ms);
    };
}
export function useDebounce(ms) {
    return useMemo(() => debounce(ms), [ms]);
}
/**
 * A wrapper around useEffect that only runs when the dependencies update.
 */
export function useUpdate(effect, dependencies = []) {
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else {
            effect();
        }
    }, dependencies);
}
export const entrantName = (e) => [e?.player1?.name, e?.player2?.name].filter((a) => a).join("/");
