import React from "react";
import { Inlet, Outlet } from "react-conduit";
import styles from "./shell.scss";
const label = "shell/nav";
export function Nav({ children }) {
    return React.createElement(Inlet, { label: label }, children);
}
export function _NavOutlet({ ...props }) {
    return React.createElement(Outlet, { label: label, ...props });
}
export function Spacer() {
    return React.createElement("div", { className: styles.spacer });
}
