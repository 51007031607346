import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTitle } from "../support/hooks";
import { Loading } from "../ui/components/loading";
import { Button } from "../ui/fields/button";
import { Nav } from "./nav";
import styles from "./shell.scss";
const NotFound = () => {
    const location = useLocation();
    useTitle("Not Found");
    return (React.createElement("div", { style: { paddingTop: 32, display: "flex", justifyContent: "center" } },
        React.createElement("div", null,
            React.createElement(Nav, null,
                React.createElement("div", { style: {
                        fontSize: 24,
                        fontWeight: "bold",
                        alignSelf: "center",
                        paddingLeft: 12,
                    } }, "Page Not Found")),
            React.createElement("p", null,
                "Either this page (",
                location.pathname,
                ") doesn\u2018t exist or you don\u2018t have access to it."),
            React.createElement("p", null, "If you believe this is in error, please contact Toast, preyneyv, or partofthecreed."))));
};
const Login = () => {
    const { loginWithPopup } = useAuth0();
    useTitle("Log In");
    return (React.createElement("div", { className: styles.loginModal },
        React.createElement("h1", null, "Stream Toolkit"),
        React.createElement(Button, { onClick: () => loginWithPopup(), big: true, style: { width: "100%" } }, "Log In")));
};
export const AuthPage = () => {
    const { isLoading, isAuthenticated } = useAuth0();
    return (React.createElement("div", null, isLoading ? (React.createElement("div", { className: styles.loadingModal },
        React.createElement(Loading, null))) : isAuthenticated ? (React.createElement(NotFound, null)) : (React.createElement(Login, null))));
};
