import classNames from "classnames";
import React from "react";
import styles from "./fields.scss";
export const Button = React.forwardRef(({ className, big, flat, icon, dirty, enabled, ...props }, ref) => (React.createElement("button", { ref: ref, className: classNames(className, styles.button, {
        [styles.big]: big,
        [styles.flat]: flat,
        [styles.icon]: icon,
        [styles.dirty]: dirty,
        [styles.enabled]: enabled,
    }), ...props })));
Button.displayName = "Button";
